<template>
    <div class="my-account-setting-container">
      <!-- 菜单 -->
      <div class="my-account-setting-menu">
        <ul>
          <li v-for="(item,index) in accountMenuData" :key="index" @click="clickAccountMenuHandler(index)">
            <p class="my-account-setting-menu-title" :style="{color:currentAccountMenuIndex===index?'#F02233':''}">{{item}}</p>
          </li>
          <div class="menu-slider-bg" :style="{left:menuSliderBgStyle}"></div>
        </ul>
      </div>
      <div class="my-account-setting-content">
        <router-view></router-view>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name:"MyAccountSetting",
    data(){
      return {
        currentAccountMenuIndex:0,
        accountMenuData:["个人资料","实名认证","密码设置"]
      }
    },
    methods:{
      clickAccountMenuHandler(index){
        this.currentAccountMenuIndex = index;
        switch(index){
          case 0:
            this.$router.push({path:"/myCenter/myAccountSetting/myProfile"},()=>{})
            break;
          case 1:
            this.$router.push({path:"/myCenter/myAccountSetting/realNameAuthentication"},()=>{})
            break;
          case 2:
            this.$router.push({path:"/myCenter/myAccountSetting/passwordModify"},()=>{})
            break;
          default:
            break;
        }
      }
    },
    computed:{
      menuSliderBgStyle(){
        return this.currentAccountMenuIndex*100+4+"px";
      }
    },
    created(){
      this.$router.push({path:"/myCenter/myAccountSetting/myProfile"},()=>{})
    }
  }
  </script>
  
  <style lang="less" scoped>
  .my-account-setting-container{
      background: #FFFFFF;
      margin-top: 1px;
      padding-top: 20px;
      padding-bottom: 30px;
      border-radius: 0px 0px 8px 8px;
      .my-account-setting-menu{
        width: 1200px;
        ul{
          margin-left: 20px;
          width: 308px;
          height: 36px;
          background: #F5F5F5;
          border-radius: 6px;
          list-style-type: none;
          padding: 0;
          display: flex;
          cursor: default;
          position: relative;
          li{
            margin-left: 4px;
            width: 100px;
            height: 36px;
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            .my-account-setting-menu-title{
              z-index: 99;
              height: 19px;
              font-family: MicrosoftYaHei;
              font-size: 14px;
              color: #808080;
              line-height: 19px;
              text-align: right;
              font-style: normal;
            }
            &:hover .my-account-setting-menu-title{
              color: #F02233;
            }
          }
          .menu-slider-bg{
            position: absolute;
            left: 4px;
            top: 4px;
            width: 100px;
            height: 28px;
            background: #FFFFFF;
            border-radius: 4px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .my-account-setting-content{
        width: 1200px;
        background: #FFFFFF;
      }
  }
  </style>